import { default as indexVxb2vPgQKhMeta } from "/opt/buildhome/repo/pages/[catSlug]-a[catId]/index.vue?macro=true";
import { default as auth_46clientoGUGf67ESyMeta } from "/opt/buildhome/repo/pages/auth.client.vue?macro=true";

let _createClientPage
async function createClientPage(loader) {
  _createClientPage ||= await import("/opt/buildhome/repo/node_modules/nuxt/dist/components/runtime/client-component").then(r => r.createClientPage)
  return _createClientPage(loader);
}
import { default as adreslerim_46cliente3fns6ZyCRMeta } from "/opt/buildhome/repo/pages/hesap/adreslerim.client.vue?macro=true";
import { default as ayarlarwutvNYRPU2Meta } from "/opt/buildhome/repo/pages/hesap/ayarlar.vue?macro=true";
import { default as logoutlNfVyNXdoIMeta } from "/opt/buildhome/repo/pages/hesap/logout.vue?macro=true";
import { default as siparislerim_46clientnOVzc9c2fvMeta } from "/opt/buildhome/repo/pages/hesap/siparislerim.client.vue?macro=true";
import { default as indexniDiYCWjuTMeta } from "/opt/buildhome/repo/pages/index.vue?macro=true";
import { default as loginzMPT6mVAPsMeta } from "/opt/buildhome/repo/pages/management/login.vue?macro=true";
import { default as _91id_93iFq71fZeZnMeta } from "/opt/buildhome/repo/pages/management/siparisler/[id].vue?macro=true";
import { default as indexfnC0ji6E41Meta } from "/opt/buildhome/repo/pages/management/siparisler/index.vue?macro=true";
import { default as _91id_93tdKoKNdyiRMeta } from "/opt/buildhome/repo/pages/management/urunler/[id].vue?macro=true";
import { default as indexQjsGaLoyuSMeta } from "/opt/buildhome/repo/pages/management/urunler/index.vue?macro=true";
import { default as iade_45ve_45degisimqZtTGPbGTdMeta } from "/opt/buildhome/repo/pages/menu/iade-ve-degisim.vue?macro=true";
import { default as odeme_46clientdQKBjYdOfSMeta } from "/opt/buildhome/repo/pages/odeme.client.vue?macro=true";
import { default as sepet8PQftGDuFrMeta } from "/opt/buildhome/repo/pages/sepet.vue?macro=true";
import { default as sifremi_45unuttum_46clientRvhIYPHjGlMeta } from "/opt/buildhome/repo/pages/sifremi-unuttum.client.vue?macro=true";
import { default as tesekkurler_46clientD3Mu7sNm70Meta } from "/opt/buildhome/repo/pages/tesekkurler.client.vue?macro=true";
import { default as _91slug_93_45_45_91urlParams_93YYNsBZcMXuMeta } from "/opt/buildhome/repo/pages/urun/[slug]--[urlParams].vue?macro=true";
export default [
  {
    name: "catSlug-acatId",
    path: "/:catSlug()-a:catId()",
    component: () => import("/opt/buildhome/repo/pages/[catSlug]-a[catId]/index.vue").then(m => m.default || m)
  },
  {
    name: "auth",
    path: "/auth",
    component: () => createClientPage(() => import("/opt/buildhome/repo/pages/auth.client.vue").then(m => m.default || m))
  },
  {
    name: "hesap-adreslerim",
    path: "/hesap/adreslerim",
    component: () => createClientPage(() => import("/opt/buildhome/repo/pages/hesap/adreslerim.client.vue").then(m => m.default || m))
  },
  {
    name: "hesap-ayarlar",
    path: "/hesap/ayarlar",
    component: () => import("/opt/buildhome/repo/pages/hesap/ayarlar.vue").then(m => m.default || m)
  },
  {
    name: "hesap-logout",
    path: "/hesap/logout",
    component: () => import("/opt/buildhome/repo/pages/hesap/logout.vue").then(m => m.default || m)
  },
  {
    name: "hesap-siparislerim",
    path: "/hesap/siparislerim",
    component: () => createClientPage(() => import("/opt/buildhome/repo/pages/hesap/siparislerim.client.vue").then(m => m.default || m))
  },
  {
    name: "index",
    path: "/",
    component: () => import("/opt/buildhome/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "management-login",
    path: "/management/login",
    meta: loginzMPT6mVAPsMeta || {},
    component: () => import("/opt/buildhome/repo/pages/management/login.vue").then(m => m.default || m)
  },
  {
    name: "management-siparisler-id",
    path: "/management/siparisler/:id()",
    meta: _91id_93iFq71fZeZnMeta || {},
    component: () => import("/opt/buildhome/repo/pages/management/siparisler/[id].vue").then(m => m.default || m)
  },
  {
    name: "management-siparisler",
    path: "/management/siparisler",
    meta: indexfnC0ji6E41Meta || {},
    component: () => import("/opt/buildhome/repo/pages/management/siparisler/index.vue").then(m => m.default || m)
  },
  {
    name: "management-urunler-id",
    path: "/management/urunler/:id()",
    meta: _91id_93tdKoKNdyiRMeta || {},
    component: () => import("/opt/buildhome/repo/pages/management/urunler/[id].vue").then(m => m.default || m)
  },
  {
    name: "management-urunler",
    path: "/management/urunler",
    meta: indexQjsGaLoyuSMeta || {},
    component: () => import("/opt/buildhome/repo/pages/management/urunler/index.vue").then(m => m.default || m)
  },
  {
    name: "menu-iade-ve-degisim",
    path: "/menu/iade-ve-degisim",
    component: () => import("/opt/buildhome/repo/pages/menu/iade-ve-degisim.vue").then(m => m.default || m)
  },
  {
    name: "odeme",
    path: "/odeme",
    component: () => createClientPage(() => import("/opt/buildhome/repo/pages/odeme.client.vue").then(m => m.default || m))
  },
  {
    name: "sepet",
    path: "/sepet",
    component: () => import("/opt/buildhome/repo/pages/sepet.vue").then(m => m.default || m)
  },
  {
    name: "sifremi-unuttum",
    path: "/sifremi-unuttum",
    component: () => createClientPage(() => import("/opt/buildhome/repo/pages/sifremi-unuttum.client.vue").then(m => m.default || m))
  },
  {
    name: "tesekkurler",
    path: "/tesekkurler",
    component: () => createClientPage(() => import("/opt/buildhome/repo/pages/tesekkurler.client.vue").then(m => m.default || m))
  },
  {
    name: "urun-slug--urlParams",
    path: "/urun/:slug()--:urlParams()",
    component: () => import("/opt/buildhome/repo/pages/urun/[slug]--[urlParams].vue").then(m => m.default || m)
  }
]